import * as moment from "moment";

export class ResourcesFakeDb {
  public static resources = [
    {
      id: "123456",
      user_id: "123456",
      name: "Trevino Marvel",
      email: "trevino@gmail.com",
      detail: {
        avatar: "https://picsum.photos/100?random=7",
        address: "15 Debenham St, Mawson, ACT",
        tfn: "4534563456",
        mobile: "1231123121",
      },
      charge_rate: 25,
      totalHours: "123",
      rating: 4,
      status: 1,
      documents: [],
      formdata: [
        {
          uuid: "747aec5e-1a28-4bd1-be57-715da1c84e31",
          type: "Accreditation",
          roles: [],
          tags: [
            "Courses",
            "Angular Master course",
          ],
          data: '[{"label":"Expires At","type":"label","value":"07/04/2027"},{"label":"Licence Number","type":"label","value":"000107220"},{"label":"Licensee","type":"label","value":"Tiago Dos Santos Ferreira Lima"},{"label":"Classes","type":"label","value":"MA Self Employed Individual With No Other Provided Persons"},{"label":"Front ","type":"image","value":"5139c170-1e1d-4ec1-b537-8ee361754937"},{"label":"Back","type":"image","value":"42cdf7ad-42b2-4045-8bfc-387c7c56a2a5"}]',
          created_by: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          creation_date_time: "2023-11-03T00:53:10.823Z",
          licence: [
            {
              label: "Expires At",
              type: "label",
              value: "07/04/2027",
            },
            {
              label: "Licence Number",
              type: "label",
              value: "000107220",
            },
            {
              label: "Licensee",
              type: "label",
              value: "Tiago Dos Santos Ferreira Lima",
            },
            {
              label: "Classes",
              type: "label",
              value:
                "MA Self Employed Individual With No Other Provided Persons",
            },
            {
              label: "Front ",
              type: "image",
              value: "5139c170-1e1d-4ec1-b537-8ee361754937",
              url: "",
            },
            {
              label: "Back",
              type: "image",
              value: "42cdf7ad-42b2-4045-8bfc-387c7c56a2a5",
              url: "",
            },
          ],
        },
        {
          uuid: "55c053e0-40aa-424d-8df6-845fc9f1fee0",
          type: "Accreditation",
          roles: [],
          tags: [
            "COVID-19",
            "Pfizer covid vaccine",
          ],
          data: '[{"label":"Expires At","type":"label"},{"label":"Licence Number","type":"label"},{"label":"Licensee","type":"label"},{"label":"Classes","type":"label"},{"label":"Front ","type":"image"},{"label":"Back","type":"image"}]',
          created_by: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          creation_date_time: "2024-08-22T12:19:36.839Z",
          licence: [
            {
              label: "Expires At",
              type: "label",
            },
            {
              label: "Licence Number",
              type: "label",
            },
            {
              label: "Licensee",
              type: "label",
            },
            {
              label: "Classes",
              type: "label",
            },
            {
              label: "Front ",
              type: "image",
            },
            {
              label: "Back",
              type: "image",
            },
          ],
        },
        {
          uuid: "76fbf1a8-107b-4a03-8528-92a27f6ab4d6",
          type: "Accreditation",
          roles: [],
          tags: [
            "Training and Induction Certificate",
            "Angular internship completion letter",
          ],
          data: '[{"label":"Expires At","type":"label"},{"label":"Licence Number","type":"label"},{"label":"Licensee","type":"label"},{"label":"Types","type":"label"},{"label":"Front ","type":"image"},{"label":"Back","type":"image"}]',
          created_by: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          creation_date_time: "2024-08-22T13:57:45.639Z",
          licence: [
            {
              label: "Expires At",
              type: "label",
            },
            {
              label: "Licence Number",
              type: "label",
            },
            {
              label: "Licensee",
              type: "label",
            },
            {
              label: "Types",
              type: "label",
            },
            {
              label: "Front ",
              type: "image",
            },
            {
              label: "Back",
              type: "image",
            },
          ],
        },
        {
          uuid: "e90346da-a1c4-4702-8b95-cab1ae6a1827",
          type: "Accreditation",
          roles: [],
          tags: [
            "Other Individual Accreditation",
            "Traffic Control",
          ],
          data: "{}",
          created_by: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          creation_date_time: "2024-08-22T13:58:49.075Z",
          licence: [],
        },

        {
          uuid: "06c09846-b119-434f-bbfe-13551ae3a2c6",
          type: "Accreditation",
          roles: [],
          tags: [
            "Driver Licence",
            "NSW Driver Licence",
          ],
          data: "{}",
          created_by: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          creation_date_time: "2024-08-22T13:59:15.767Z",
          licence: [],
        },
        {
          uuid: "f3d7ab39-8432-4e58-8699-bbb36f80bafd",
          type: "Accreditation",
          roles: [],
          tags: [
            "Security Licence",
            "NSW Security Licence",
          ],
          data: '[{"label":"Expires At","type":"label"},{"label":"Licence Number","type":"label"},{"label":"Licensee","type":"label"},{"label":"Classes","type":"label"},{"label":"Front ","type":"image"},{"label":"Back","type":"image"}]',
          created_by: "6c6a3bb9-d583-43b2-b5a3-7ebc2e2b4a29",
          creation_date_time: "2024-08-22T13:59:27.077Z",
          licence: [
            {
              label: "Expires At",
              type: "label",
            },
            {
              label: "Licence Number",
              type: "label",
            },
            {
              label: "Licensee",
              type: "label",
            },
            {
              label: "Classes",
              type: "label",
            },
            {
              label: "Front ",
              type: "image",
            },
            {
              label: "Back",
              type: "image",
            },
          ],
        },
      ],
      accreditation: [
        {
          uuid: "09506064-06f8-42b3-919f-867207b1bfa0",
          category: "Security Licence",
          accreditation: "NSW Security Licence",
          number: "410459957",
          startdate: "2010-10-10",
          enddate: "2020-11-11",
          primary: true,
          updated: "2020-12-12:10:00:00Z",
          pinged: "2020-12-12:10:00:00Z",
          type: "certificate",
          status: "Expired",
          classes: [
            {
              code: "",
              name: "Unarmed Guard",
            },
          ],
          documents: [
            {
              type: "Accreditation",
              accreditation: "NSW Liquor Licence",
              title: "Security document.",
              description: "Some description",
              private: true,
              primary: true,
              location: "/usr/whatever",
            },
          ],
        },
      ],
      notes: [
        {
          author_id: "yt4f53y4tf52",
          author: "Daria D",
          datetime: moment(),
          note: "new note for Trevino Marvel",
        },
        {
          author_id: "y34534545gdfgd52",
          author: "Daria D",
          datetime: moment().subtract(2, "hour"),
          note: "new note for Trevino Marvel",
        },
      ],
      contacts: [],
      events: []
    },
    {
      id: "4321432143214321",
      user_id: "4321432143214321",
      name: "Trevino Marvel2",
      email: "trevino2@gmail.com",
      detail: {
        mobile: "12311231212",
        address: "15 Debenham St, Mawson, ACT",
      },
      totalHours: "321",
      rating: 3,
      status: 2,
      charge_rate: 15,
      documents: [],
      accreditation: [
        {
          id: "grt34354rtgr43",
          type: "Security Licence",
          accreditation: "WA Security Number",
          licenceNumber: "5532234523",
          class: "",
          expiryDate: "09/06/2023",
          file: "",
        },
      ],
      contacts: [],
      events: []
    },
  ];

  public static venueResourceRequirements = [
    {
      uuid: "yt6756rtr545er3456765erfgf",
      name: "Tyson Cherry",
      chargePlan: "Discount",
      chargeRates: "20$/h",
      avatar: "https://picsum.photos/100?random=8",
    },
    {
      uuid: "y5443256rw22454",
      name: "Trevino Marvel",
      chargePlan: "Standart",
      chargeRates: "30$/h",
      avatar: "https://picsum.photos/100?random=7",
    },
  ];

  public static controlsForNSWSecurityLicence = [
    "licenceNumber",
    "expiresAt",
    "type",
    "licenceStatus",
    "accreditationNumberOfServiceAlcohol",
    "expiresAt2",
    "classAServiceOfAlcohol",
    "classGServiceOfAlcohol",
    "classHServiceOfAlcohol",
    "classPServiceOfAlcohol",
    "typeNSWServiceOfAlcohol",
  ];
  public static controlsForNSWResponsibleServiceOfAlcohol = [
    "licenceNumberOfNSWAlcohol",
    "expiresAtNSWAlcohol",
    "classA",
    "classG",
    "classH",
    "classP",
    "typeLicence",
    "typeNSWSecurityLicenceOfAlcoholBottom",
    "accreditationStatusSecurityLicenceOfAlcoholBottom",
    "accreditationNumberOfSecurityLicenceBottom",
    "startAtSecurityLicenceBottom",
    "expiresAtSecurityLicenceBottom",
    "class1ASecurityLicenceBottom",
    "class1BSecurityLicenceBottom",
    "class1CSecurityLicenceBottom",
    "class1DSecurityLicenceBottom",
    "class1ESecurityLicenceBottom",
    "class1FSecurityLicenceBottom",
    "class1GSecurityLicenceBottom",
    "class2ASecurityLicenceBottom",
    "class2BSecurityLicenceBottom",
    "class2CSecurityLicenceBottom",
    "class2DSecurityLicenceBottom",
    "classMASecurityLicenceBottom",
    "classMBSecurityLicenceBottom",
    "classMCSecurityLicenceBottom",
    "classMDSecurityLicenceBottom",
    "classMESecurityLicenceBottom",
  ];
  public static controlsForVIKSecurityLicence = [
    "licenceNumber",
    "expiresAtSecurityLicenceVICBottom",
    "typeVICSecurityLicenceBottom",
    "accreditationStatusSecurityLicenceVICBottom",
    "expiresAtSecurityLicenceOfAlcoholVICBottom",
    "expiresAt3",
    "type2",
  ];
  public static controlsForVICResponsibleServiceOfAlcohol = [
    "expiresAtVICAlcohol",
    "typeVICResponsibleServiceOfAlcohol",
    "accreditationStatusVICResponsibleServiceOfAlcohol",
    "accreditationNumberOfSecurityLicenceVIC",
    "expiresAtSecurityLicenceVIC",
    "crowdControllerSecurityLicenceVIC",
    "securityGuardSecurityLicenceVIC",
    "privateSecurityTrainerSecurityLicenceVIC",
    "bodyguardSecurityLicenceVIC",
    "investigatorSecurityLicenceVIC",
    "typeSecurityLicenceVIC",
    "expiresAtVICResponsibleServiceOfGaming",
    "typeVICResponsibleServiceOfGaming",
  ];
  public static controlsForQLDSecurityLicence = [
    "licenceNumber",
    "expiresAtQLDSecurityLicence",
    "typeQLDSecurityLicenceSecurityOperations",
    "accreditationStatusQLDSecurityLicenceSecurityOperations",
    "accreditationStatusQLDResponsibleServiceOfAlcohol",
    "expiresAtQLDResponsibleServiceOfGambling",
  ];
  public static controlsForQLDResponsibleServiceOfAlcohol = [
    "status",
    "typeQLDResponsibleServiceSecurityOperations",
    "accreditationStatusQLDResponsibleServiceSecurityOperations",
    "accreditationNumberOfSecurityLicenceQLD",
    "expiresAtSecurityLicenceQLD",
    "crowdControllerSecurityLicenceQLD",
    "privateInvestigatorSecurityLicenceQLD",
    "bodyguardSecurityLicenceQLD",
    "unarmedSecurityLicenceQLD",
    "cashInTransitSecurityLicenceQLD",
    "monitoringSecurityLicenceQLD",
    "dogPatrolSecurityLicenceQLD",
    "securityAdvisorSecurityLicenceQLD",
    "equipmentInstallerSecurityLicenceQLD",
    "firmClass1SecurityLicenceQLD",
    "firmClass2SecurityLicenceQLD",
    "expiresAtOfResponsibleServiceOfGamblingQLD",
  ];
  public static controlsForWASecurityLicence = [
    "licenceNumberWA",
    "expiresAtWASecurityLicence",
    "crowdControlAgent",
    "crowdController",
    "inquiryAgent",
    "investigator",
    "securityAgent",
    "securityBodyguard",
    "securityConsultant",
    "securityInstaller",
    "securityOfficer",
    "securityCompany",
    "expiresAtFirstAidWASecurityLicence",
    "typeWACertificateInSecurityOperations",
    "accreditationStatusWACertificateInSecurityOperations",
    "accreditationStatusWAResponsibleServiceOfAlcohol",
  ];
  public static controlsForWAResponsibleServOfAlcohol = [
    "typeWACertificateInSecurityOperationsResponsibleServiceOfAlcohol",
    "status",
    "accreditationStatusWACertificateInSecurityOperationsResponsibleServiceOfAlcohol",
    "accreditationNumberOfSecurityLicenceWA",
    "expiresAtWASecurityLicenceOfAlcohol",
    "crowdControlAgentWASecurityLicence",
    "crowdControllerWASecurityLicence",
    "inquiryAgentWASecurityLicence",
    "investigatorWASecurityLicence",
    "securityAgentWASecurityLicence",
    "securityBodyguardAgentWASecurityLicence",
    "securityConsultantWASecurityLicence",
    "securityInstallerWASecurityLicence",
    "securityOfficerWASecurityLicence",
    "securityCompanyWASecurityLicence",
  ];
}
